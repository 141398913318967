<template>
  <div
    style="width:60%; height:100%; padding:10px; display:flex; flex-direction:column; justify-content:center; align-items:center;"
  >
    <iframe
      width="100%"
      height="100%"
      frameborder="0"
      style="border:0"
      :src="
        `https://www.google.com/maps/embed/v1/place
  ?key=AIzaSyATI-pRchP7-f0ty8rsP6_Km-3cLvLf6OE
  &q=${coordinates.lat},${coordinates.lng}&zoom=18`
      "
      allowfullscreen
    >
    </iframe>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'GoogleMaps',
  props: ['coordinates'],
  data() {
    return {}
  },
  created() {},
  computed: {
    ...mapGetters([])
  },
  watch: {},
  methods: {
    ...mapActions([])
  }
}
</script>

<style scoped></style>
